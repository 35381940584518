import React from 'react';
import Layout from '../components/Layout/Layout';
import SEO from '../components/seo';
import styled from 'styled-components';
import Img from 'gatsby-image';
import BlockContent from '@sanity/block-content-to-react';
import ReactPlayer from 'react-player';

const PostWrapper = styled.main`
	a {
		color: inherit;
	}
`;

const PostIntroSection = styled.section`
	display: flex;
	flex-direction: column;
`;

const PostIntro = styled.aside`
	width: 100%;
	padding: 50px 15px;
	box-sizing: border-box;
	text-align: center;

	@media (min-width: 768px) {
	}

	@media (min-width: 1024px) {
		padding: 75px 50px;
		flex: 1;
		text-align: left;

		&.centered {
			text-align: center;

			.subtitle {
				max-width: 50ch;
				padding: 0;
				margin: 0 auto 40px;
			}
		}
	}
`;

const PostIntroHero = styled.figure`
	width: 100%;
	@media (min-width: 1024px) {
		flex: 1;
	}
`;

const PodcastEmbed = styled.article`
	margin: 50px auto;
	max-width: 700px;
`

const PostTitle = styled.h1`
	font-size: 2em;
	margin-bottom: 10px;

	@media (min-width: 768px) {
		margin-bottom: 15px;
	}

	@media (min-width: 1024px) {
		font-size: 3em;
		margin-bottom: 20px;
	}

	@media (min-width: 1400px) {
		font-size: 4em;
	}

	@media (min-width: 1800px) {
		font-size: 5em;
	}
`;

const PostSubTitle = styled.h2`
	font-size: 1.3333333333333em;

	@media (min-width: 1024px) {
		font-size: 1.5em;
		padding-right: 20px;
		margin-bottom: 20px;
	}

	@media (min-width: 1400px) {
		font-size: 2em;
		margin-bottom: 40px;
	}
`;

const HeroImage = styled.img`
	max-width: 100%;
	width: 100%;
`;

const PostBodyWrapper = styled.main`
	padding: 25px 0px 50px;

	@media (min-width: 1024px) {
		padding: 0 0 75px;
	}
`;

const IntroText = styled.h3`
	font-size: 1.75em;
	padding: 25px 20px;
	line-height: 1.125;
	font-weight: normal;

	@media (min-width: 1024px) {
		padding: 75px 20px;
		font-size: 1.75em;
	}

	@media (min-width: 1200px) {
		padding: 100px 50px 50px;
		font-size: 2.25em;
		max-width: 1200px;
		margin: 0 auto;
	}

	@media (min-width: 1600px) {
		padding: 150px 50px 100px;
		font-size: 2.5em;
		max-width: 1400px;
	}

	@media (min-width: 1800px) {
		font-size: 3em;
	}
`;

const BodyText = styled.section`
	padding: 25px 15px;

	h1,
	h2,
	h3,
	h4 {
		display: block;
		padding-bottom: 10px;
	}

	strong,
	p {
		line-height: 1.3333;
	}

	em {
		font-style: italic;
	}

	p {
		font-size: 18px;
		margin-bottom: 1rem;
	}

	ol {
		list-style: decimal-leading-zero;
		padding: 10px 40px;
		margin-bottom: 1em;
	}

	ul {
		li {
			list-style: none;
			padding-bottom: 5px;
			&:before {
				content: '●';
				margin-right: 10px;
			}
		}
	}

	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}

	@media (min-width: 768px) {
		p {
			max-width: 65ch;
		}
	}

	@media (min-width: 1024px) {
		p {
			font-size: 22px;
		}

		ol {
			padding: 20px 40px 24px;
			font-size: 20px;
		}

		font-size: 1.2307692308em;
		max-width: 750px;
		padding: 0 20px;
		margin: 50px auto;
	}
`;

const PullQuote = styled.section`
	font-style: italic;
	text-align: center;
	padding: 25px 20px;

	p,
	em {
		line-height: 1.3333;
		font-size: 22px;
	}

	@media (min-width: 1024px) {
		padding: 25px 50px;
		p,
		em {
			margin: 0 auto;
			max-width: 32ch;
			font-size: 2em;
		}
	}

	@media (min-width: 1200px) {
		padding: 50px;
		p,
		em {
			font-size: 2.25em;
		}
	}

	@media (min-width: 1600px) {
		p,
		em {
			font-size: 2.5em;
		}
	}

	@media (min-width: 1800px) {
		p,
		em {
			font-size: 2.75em;
		}
	}
`;

const FullBleedImage = styled.figure`
	width: 100%;
	padding: 25px 0;

	@media (min-width: 1024px) {
		padding: 75px 0;
	}
`;

const ImageCaption = styled.figcaption`
	padding: 10px 0 20px 10px;
	font-size: 0.75em;

	@media (min-width: 1024px) {
		padding: 10px 0 0 20px;
	}
`;

const InlineImage = styled.figure`
	padding: 25px 15px;
	@media (min-width: 1024px) {
		max-width: 700px;
		padding: 50px 20px;
		margin: 0 auto;
	}
`;

const TwoPhotoWide = styled.aside`
	display: flex;
	justify-content: space-between;
	padding: 25px 15px;

	@media (min-width: 1024px) {
		padding: 20px;
	}
`;

const TwoPhotoMedium = styled(TwoPhotoWide)`
	padding: 25px 15px;
	display: flex;
	flex-direction: column;

	@media (min-width: 1024px) {
		justify-content: space-between;
		max-width: 75%;
		margin: 0 auto;
		flex-direction: row;
	}
`;

const ImgArticle = styled.article`
	flex: 1;

	img {
		width: 100%;
		max-width: 100%;
	}

	@media (min-width: 1024px) {
		max-width: calc(50% - 10px);
		img {
		}
	}
`;

const VideoSection = styled.section`
	margin: 25px 0;

	&.youtube {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		max-width: 100%;

		iframe,
		object,
		embed {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	&.vimeo {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		max-width: 100%;

		iframe,
		object,
		embed {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	@media (min-width: 1024px) {
		margin: 100px 0;
	}
`;

const Line = styled.div`
	height: 1px;
	width: calc(100% - 30px);
	margin: 0 auto;
	border-top: 1px solid #131313;

	@media (min-width: 1024px) {
		max-width: 750px;
		margin: 75px auto 50px;
	}
`;

const Annotation = styled.sup`
	display: inline-block;
	position: relative;
	font-size: 0.5em;
	top: -0.75em;
	left: 2px;
	color: #00f;
	text-decoration: underline;
	cursor: pointer;

	&:hover {
		div {
			opacity: 1;
		}
	}
`;

const AnnotationText = styled.div`
	color: #131313;
	position: absolute;
	top: -15px;
	left: 5px;
	padding: 5px 5px 0;
	opacity: 0;
	transition: opacity 0.25s ease;
	background-color: rgba(250, 204, 0, 0.25);
	display: flex;
	width: max-content;
	align-content: center;
`;

const AnnotationCopy = styled.span`
	display: inline-block;
	font-size: 12px;
	padding-right: 5px;
`;

const AnnotationLink = styled.a`
	display: inline-block;
	font-size: 12px;
	color: inherit;
	padding-left: 5px;
`;

const serializers = {
	marks: {
		annotation: ({ mark, children }) => {
			const { number, source, title, url } = mark;
			return (
				<>
					{children}
					<Annotation>
						{number}{' '}
						<AnnotationText>
							<AnnotationCopy>{title}</AnnotationCopy> 
							<AnnotationLink
								href={url}
								target='_blank'
								rel='noopener noreferrer'>
								{source}
							</AnnotationLink>
						</AnnotationText>
					</Annotation>
				</>
			);
		},
		link: ({ mark, children }) => {
			const { blank, href } = mark;
			return blank ? (
				<a href={href} target='_blank' rel='noopener noreferrer'>
					{children}
				</a>
			) : (
				<a href={href}>{children}</a>
			);
		},
	},
};
const BlogPost = ({ pageContext }) => {
	const serializeSections = section => {
		switch (section._type) {
			case 'fullBleedPhoto':
				return (
					<FullBleedImage key={section._key}>
						<Img fluid={section.image.asset.fluid} />
						{section.imageCaption && (
							<ImageCaption>{section.imageCaption}</ImageCaption>
						)}
					</FullBleedImage>
				);
			case 'bodyText':
				const textBlocks = pageContext._rawBody.find(
					item => item._key === section._key,
				);
				return (
					<BodyText>
						<BlockContent
							blocks={textBlocks.textContent}
							key={section._key}
							serializers={serializers}
						/>
					</BodyText>
				);
			case 'inlinePhoto':
				return (
					<InlineImage key={section._key}>
						<Img fluid={section.image.asset.fluid} />
						{section.imageCaption && (
							<ImageCaption>{section.imageCaption}</ImageCaption>
						)}
					</InlineImage>
				);
			case 'twoPhotoMedium':
				return (
					<TwoPhotoMedium key={section._key}>
						{section.images.map((img, index) => {
							return (
								<ImgArticle key={index}>
									<img
										src={img.image.asset.url}
										alt={img?.imageCaption}
									/>
									{img.imageCaption && (
										<ImageCaption>
											{img.imageCaption}
										</ImageCaption>
									)}
								</ImgArticle>
							);
						})}
					</TwoPhotoMedium>
				);
			case 'pullQuote':
				const quoteBlocks = pageContext._rawBody.find(
					item => item._key === section._key,
				);
				return (
					<PullQuote>
						<BlockContent blocks={quoteBlocks.quote} />
					</PullQuote>
				);
			case 'externalVideo':
				const youTube = section.videoUrl.includes('youtube');
				const vimeo = section.videoUrl.includes('vimeo');

				return (
					<VideoSection
						className={`${youTube ? 'youtube' : ''} ${
							vimeo ? 'vimeo' : ''
						}`}>
						<ReactPlayer url={section.videoUrl} controls={true} />
					</VideoSection>
				);

			case 'lineSeparator':
				return <Line />;
			default:
		}
	};


	return (
		<React.Fragment>
			<SEO title={pageContext.title} />
			<PostWrapper>
				<PostIntroSection>
					<PostIntro>
						<PostTitle>{pageContext.title}</PostTitle>

						{pageContext.subtitle && (
							<PostSubTitle className='subtitle'>
								{pageContext.subtitle}
							</PostSubTitle>
						)}
					</PostIntro>
					<PostIntroHero>
						{pageContext.heroImage && (
							<HeroImage
								src={pageContext.heroImage.asset.url}
								alt={pageContext.title}
							/>
						)}
						{pageContext?.heroImageCaption && (
							<ImageCaption>
								{pageContext.heroImageCaption}
							</ImageCaption>
						)}
					</PostIntroHero>
				</PostIntroSection>
				<PostBodyWrapper>
					{pageContext.introText && (
						<IntroText>{pageContext.introText}</IntroText>
					)}

					<PodcastEmbed>
						<div dangerouslySetInnerHTML={{__html: pageContext.podcastEmbed}} />
					</PodcastEmbed>
					{pageContext.body.map(section =>
						serializeSections(section),
					)}
				</PostBodyWrapper>
			</PostWrapper>
		</React.Fragment>
	);
};
export default BlogPost;
